* :focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.google-sign-in {
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 16px !important;
}

#parent .hidden-child {
    visibility: hidden;
}

#parent:hover .hidden-child {
    visibility: visible;
}

*:focus {
    box-shadow: none !important;
}

*[data-focus] {
    box-shadow: none !important;
}

.hover {
    cursor: pointer;
}

.badge {
    position: absolute;
    top: 5px;
    right: 9px;
    padding: 5px 5px;
    border-radius: 50%;
    background: #ff3d71;
    color: white;
}
